const members_2024 = [
  {
    name: 'Pratik Singh',
    image: 'images/Alumni_All_Images/Graduating Batch of 2024/Pratik.jpeg',
    linkedin: 'https://www.linkedin.com/in/pratik-singh-4026311b9/',
    degree: '',
    graduation_year: 2024,
    workplace: 'Sony Research India',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Anushka Chatterjee',
    image: 'images/Alumni_All_Images/Graduating Batch of 2024/Anushka.jpeg',
    linkedin: 'https://www.linkedin.com/in/anushka-chatterjee-7483a9212/',
    degree: '',
    graduation_year: 2024,
    workplace: 'Nomura',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Sneha Majumder',
    image: 'images/Alumni_All_Images/Graduating Batch of 2024/Sneha.jpeg',
    linkedin: 'https://www.linkedin.com/in/sneha-majumder-21aa4a210/',
    degree: '',
    graduation_year: 2024,
    workplace: 'Flipkart',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Shaswat Shrivastav',
    image: 'images/Alumni_All_Images/Graduating Batch of 2024/Shaswat.jpeg',
    linkedin: 'https://www.linkedin.com/in/shaswat-shrivastav-95096a219/',
    degree: '',
    graduation_year: 2024,
    workplace: 'Commonwealth Bank',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Shashwat Naidu',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2024/Shashwat_Naidu.jpeg',
    linkedin: 'https://www.linkedin.com/in/shashwat-naidu-258413225',
    degree: '',
    graduation_year: 2024,
    workplace: 'Samsung Research India',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Omee Katrodiya',
    image: 'images/Alumni_All_Images/Graduating Batch of 2024/Omee.jpeg',
    linkedin: 'https://www.linkedin.com/in/omee-katrodiya',
    degree: '',
    graduation_year: 2024,
    workplace: 'Applied Bell Curve',
    designation: '',
    additional_info: '',
  },
];

export default members_2024;
